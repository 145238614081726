export default function IconCancel() {
  return (
    <svg
      id="icon_cancelar"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        id="Caminho_4313"
        data-name="Caminho 4313"
        d="M0,0H24V24H0Z"
        fill="none"
      />
      <path
        id="Caminho_4314"
        data-name="Caminho 4314"
        d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Zm0-2a8,8,0,1,0-8-8A8,8,0,0,0,12,20Zm0-9.414,2.828-2.829,1.415,1.415L13.414,12l2.829,2.828-1.415,1.415L12,13.414,9.172,16.243,7.757,14.828,10.586,12,7.757,9.172,9.172,7.757Z"
        fill="#d83079"
      />
    </svg>
  );
}
