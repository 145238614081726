import styled from "styled-components";

type FeedbackType = {
  isOpen: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  height: 100%;

  background: #f2f5fc;
  padding: 40px;

  @media (max-width: 680px) {
    grid-template-columns: 1fr;

    width: 100%;
  }
`;

export const PanelLeft = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1.875rem;
    font-weight: bold;
    color: var(--darkBlack);
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 40px;

    h2 {
      font-size: 1.25rem;
      font-weight: bold;
      color: var(--darkBlack);
    }

    @media (max-width: 680px) {
      margin-top: 20px;
    }

    .field {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      margin-top: 30px;

      .requestCancel {
        width: 100%;
        margin-top: 34px;
      }
    }
  }
`;

export const PanelRight = styled.div`
  display: flex;
  flex-direction: column;

  border-left: 1px solid rgba(149, 149, 149, 0.4);
  padding-left: 60px;
  margin-top: 98px;

  h2 {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--darkBlack);
    margin-bottom: 20px;
  }

  @media (max-width: 680px) {
    border-left: 0;
    padding-left: 0;
    margin-top: 0;
  }
`;

export const BackToHome = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;
  padding: 40px;

  width: 36%;
  background: #fff;

  @media (max-width: 680px) {
    width: 100%;
  }

  .saveButton {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 220px;
    background-color: var(--green);
    color: #fff;
    padding: 12px 0;
    font-size: 1em;

    :hover {
      background: var(--green);
      filter: brightness(0.97);
    }
  }

  .backHome {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 40px;
    padding: 12px;

    border: none;
    background: transparent;

    font-size: 1rem;
    font-weight: bold;
    color: var(--green);

    svg {
      margin-right: 8px;
    }

    transition: filter 0.3s;
    &:hover {
      transition: filter 0.3s;
      filter: brightness(0.8);
    }
  }
`;

export const FeedbackContainer = styled.div<FeedbackType>`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};

  .backgroundOverlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;

    background: var(--gray);
    opacity: 0.5;
  }

  .boxContainer {
    position: absolute;
    inset: 0;
    margin: auto;
    z-index: 3;
    padding: 0 80px;
    text-align: center;

    max-width: 480px;
    height: 65%;

    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      color: var(--darkBlack);
      font-size: 36px;
      font-weight: 600;
      line-height: 40px;
      margin-bottom: 30px;
    }

    .subtitle {
      margin-bottom: 80px;
      color: var(--gray);
    }

    .nextButton {
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 220px;
      background-color: var(--green);
      color: #fff;
      padding: 12px 0;
      font-size: 1em;

      :hover {
        background: var(--green);
        filter: brightness(0.97);
      }
    }
  }
`;
